// eslint-disable-next-line no-redeclare,no-unused-vars
function getData(
  object,
  id,
  key,
  objectSub,
  element,
  callback,
  emptyValue = null
) {
  if (!id || object == 'refObj') return '';

  if (isArray(id)) {
    const values = [];
    id.forEach(function (idSingle) {
      values.push(
        getData(object, idSingle, key, objectSub, element, callback, emptyValue)
      );
    });
    return values;
  }

  var values = [object, id];
  if (key) values.push(key);
  var value = getObjectValue(data, values);
  if (!defined(value)) value = getObjectValue(dataTmp, values);
  if (defined(value)) return value;
  if (id < 0) return;

  // get data request
  var ident = md5(object + id + key);
  if (!inArray(ident, dataLoaded)) {
    dataLoaded.push(ident);
    var pm = {object: object, objectSub: objectSub, id: id, background: true};
    if (callback)
      pm.callback = function () {
        callback(getObjectValue(data, values));
      };
    else if (element)
      pm.callback = function () {
        element.val(getObjectValue(data, values));
      };
    ajax(pm);
  }

  if (isset(emptyValue)) return emptyValue;
  else if (key && element && element.is('input')) return '';
  else if (key) return '...';
  else return {};
}
